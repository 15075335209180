<template>
    <div class="merchantwork">
        <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
            <div class="merchantwork-wrap">
                <div class="merchantwork-wrap-top">
                    <div class="merchantwork-wrap-top-t">
                        <div class="left">
                            <img :src="shopInfo.shop_logo" class="logo" alt />
                            <div class="content-box">
                                <p>{{ shopInfo.shop_name }}</p>
                                <div class="flex bd_wx">
                                    <img src="@/assets/images/b_wx.png" class="icon" alt />
                                    <div class="bd" v-if="shopUserInfo.is_auth === 1">已绑定</div>
                                    <router-link tag="div" class="bd go" :to="'/WechatAuth'"
                                        v-if="shopUserInfo.is_auth === 0">去绑定</router-link>
                                </div>
                            </div>
                        </div>
                        <router-link to="/storeinformation" tag="p" class="set">
                            <i class="iconfont icon-shezhi"></i>
                        </router-link>
                    </div>

                    <ul class="merchantwork-wrap-top-b">
                        <li>
                            <p>今日收益</p>
                            <p>￥{{ shopWallet.today_income | numFloat }}</p>
                        </li>
                        <li>
                            <p>当前资产</p>
                            <p>￥{{ shopWallet.shop_wallet | numFloat }}</p>
                        </li>
                        <li>
                            <p>累计收益</p>
                            <p>￥{{ shopWallet.total_income | numFloat }}</p>
                        </li>
                    </ul>
                </div>
                <div class="merchantwork-wrap-center">
                    <div @click="scanQRCode">
                        <img src="@/assets/saoma.png" alt />
                        <p>扫码核销</p>
                    </div>
                    <div @click="showpopup">
                        <img src="@/assets/hexiaoma.png" alt />
                        <p>核销码核销</p>
                    </div>
                </div>
                <div class="merchantwork-wrap-bottom">
                    <p class="tool">常用工具</p>
                    <div class="merchantwork-wrap-bottom-list">
                        <ul class="merchantwork-wrap-bottom-list-1">
                            <li>
                                <router-link to="/Ordermangement" tag="div">
                                    <img :src="require('@/assets/sj01.png')" alt />
                                    <p>预约管理</p>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/hexiaorecords" tag="div">
                                    <img :src="require('@/assets/sj02.png')" alt />
                                    <p>核销记录</p>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/shopApply" tag="div">
                                    <img :src="require('@/assets/sj03.png')" alt />
                                    <p>收益提现</p>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/shopAccount?from=1" tag="div">
                                    <img :src="require('@/assets/sj04.png')" alt />
                                    <p>我的银行卡</p>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/shopWithdraw" tag="div">
                                    <img :src="require('@/assets/sj05.png')" alt />
                                    <p>提现记录</p>
                                </router-link>
                            </li>
							
							<li>
							    <router-link to="/writeOffStatistics" tag="div">
							        <img :src="require('@/assets/sj02.png')" alt />
							        <p>核销统计</p>
							    </router-link>
							</li>
                            <li>
                                <router-link to="/restpassword" tag="div">
                                    <img :src="require('@/assets/sj06.png')" alt />
                                    <p>修改密码</p>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/newproblems?type=3" tag="div">
                                    <img :src="require('@/assets/sj07.png')" alt />
                                    <p>常见问题</p>
                                </router-link>
                            </li>
                            <li @click="makePhone"> 
                                <img :src="require('@/assets/kefu02.png')" alt />
                                <p>平台热线</p> 
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="merchantwork-wrap-footer" @click="outLogin">
                    <p>退出登录</p>
                </div>
				<div class="footer1">
				    <p>版权所有：饭十荟 商户版本号：V1.3.10</p>
				    <!-- <p>新晋时代提供技术支持</p> -->
				</div> 
            </div>
        </van-pull-refresh>
        
        <!-- //弹窗提示核销码核销 -->
        <div class="popup" v-show="popup">
            <!-- 内容 -->
            <div class="popup-content">
                <p class="hexiao">核销码核销</p>
                <p class="input">
                    <input type="number" placeholder="请输入核销码" v-model="check_code" />
                </p>
                <div class="popup-content-1">
                    <p @click="closepopup">取消</p>
                    <p @click="nextTip">下一步</p>
                </div>
            </div>
            <div class="yinyi"></div>
        </div>
    </div>
</template>

<script>
    import {
        getShopWallet,
        BuessDetail,
        verificationCheck
    } from "@/http/api";
    import {
        scanQRCode,closeWindow
    } from "@/common/wxJssdk";

    export default {
        data() {
            return {
                popup: 0,
                isLoading: false,
                check_code: "",
                shopUserInfo: {},
                shopInfo:{},
                shopWallet: {},
                result: "",
            };
        },
        
        beforeCreate() {
            if (!this.$LStorage.getItem("shopUserInfo")) {
                this.$router.replace({
                    path: "/merchatlogin"
                });
            }
        },

        mounted() {
            this.shopUserInfo = this.$LStorage.getItem("shopUserInfo");
            this.BuessDetail();
            this.getShopWallet();
            
            // 处理扫码问题
            let from = this.$route.query.from,
                checkCode = this.$route.query.check_code;
            if (from && from == "QRCode" && checkCode) {
                this.check_code = checkCode;
                this.nextTip();
            }
        },
        methods: {
            //获取商家详情
            async BuessDetail() {
                const res = await BuessDetail({
                    data: {
                        shop_id: this.shopUserInfo.shop_id
                    }
                });
                //console.log(res)
                this.shopInfo = res.data;
            },
            
            //获取店铺资金
            async getShopWallet() {
                const res = await getShopWallet({
                    data: {
                        shop_id: this.shopUserInfo.shop_id
                    }
                });
                //console.log(res)
                this.shopWallet = res.data;
            },
            
            // 刷新
            onRefresh() {
                this.BuessDetail();
                this.getShopWallet();
                this.$toast("刷新成功");
                this.isLoading = false;
            },

            // 扫码核销
            scanQRCode() {
                scanQRCode(res => {
                    if (res.status) {
                        this.check_code = this.getString(res.result, "check_code"); 
                        this.nextTip();
                    }
                });
            },

            // 下一步
            nextTip() {
                if (this.check_code.length != 16) {
                    this.$toast.fail("请正确输入核销码");
                } else {
                    this.verificationCheck();
                }
            },

            // 验证核销
            async verificationCheck() {
                const res = await verificationCheck({
                    data: {
                        check_code: this.check_code,
                        shop_user_id: this.shopUserInfo.shop_user_id
                    }
                });
                if (res.code == "200") {
                    this.closepopup();
                    this.$router.push({
                        path: "/confirmhexiao",
                        query: res.data
                    });
                } else {
                    this.$dialog.alert({
                        title: "提示",
                        message: res.msgs
                    });
                }
            },
            
            showpopup() {
                this.popup = 1;
            },
            closepopup() {
                this.popup = 0;
                this.check_code = "";
            },

            // 执行点击事件
            makePhone() {
                this.$store.dispatch("changeMakePhone", true);
            },
            
            // 退出登录
            outLogin() {
                this.$dialog.alert({
                    title: "提示",
                    message: "退出成功"
                }).then(() => { 
                    this.$LStorage.removeItem("shopUserInfo");
                    // 关闭
                    closeWindow();
                });
            },
            
            // 截取check_code
            getString(url, variable) {
                var query = url.split("?")[1];
                var vars = query.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    if (pair[0] == variable) {
                        return pair[1];
                    }
                }
                return false;
            },
            
        },
        filters: {
            numFloat(num) {
                if (num) {
                    num = Number(num);
                    return num.toFixed(2);
                }
                return "0.00";
            }
        },
        created() {}
    };
</script>

<style lang="scss" scoped>
    //核销码弹窗
    .popup {
        width: 100%;
        height: 100%;

        .popup-content {
            width: 5.17rem;
            height: max-content;
            background: #fff;
            border-radius: 0.08rem;
            position: fixed;
            top: 20%;
            // bottom: 0;
            left: 0;
            right: 0;
            padding-bottom: 0.4rem;
            margin: 0 auto;
            z-index: 100;

            .hexiao {
                width: 100%;
                height: 0.88rem;
                line-height: 0.88rem;
                background: #ff6521;
                border-radius: 0.07rem 0.07rem 0 0;
                font-size: 0.36rem;
                color: #fff;
                text-align: center;
            }

            .input {
                width: 4.68rem;
                height: 0.88rem;
                background: #f5f5f5;
                font-size: 0.26rem;
                line-height: 0.88rem;
                margin-top: 0.41rem;
                margin-left: 0.23rem;
                padding-left: 0.22rem;
                box-sizing: border-box;
            }

            .popup-content-1 {
                display: flex;
                margin-top: 1.12rem;
                margin-left: 0.32rem;

                p {
                    width: 2.1rem;
                    height: 0.75rem;
                    margin-right: 0.33rem;
                    font-size: 0.3rem;
                    text-align: center;
                    line-height: 0.75rem;
                    border-radius: 0.38rem;
                }

                p:nth-child(1) {
                    background: #e4e4e4;
                    color: #585858;
                }

                p:nth-child(2) {
                    background: #ff6521;
                    color: #fff;
                }
            }
        }

        .yinyi {
            width: 100%;
            height: 100vh;
            background: rgba($color: #000000, $alpha: 0.6);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .merchantwork {
        width: 100%;
        height: 100%;
        background: #f6f6f6;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .merchantwork-wrap {
            width: 100%;

            .merchantwork-wrap-top {
                width: 100%;
                height: 4.11rem;
                background-image: url("../../../assets/shangjia.png");
                background-position: 0 0;
                background-size: 100% 4.11rem;
                padding: 0.41rem 0.33rem 0 0.28rem;
                box-sizing: border-box;

                .merchantwork-wrap-top-t {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .left {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: calc(100% - 0.6rem);

                        .logo {
                            width: 1.4rem;
                            height: 1.4rem;
                            border-radius: 0.1rem;
                            border: 1px solid #fff;
                        }

                        .content-box {
                            width: calc(100% - 1.8rem);

                            p {
                                font-size: 0.3rem;
                                color: #fff;
                                // line-height: 1.2rem;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .bd_wx {
                                font-size: 0.28rem;
                                color: #fff;
                                align-items: center;
                                margin-top: 0.2rem;

                                .icon {
                                    width: 0.42rem;
                                    height: 0.42rem;
                                    margin-right: 0.1rem;
                                }

                                .go {
                                    color: #ffaa23;
                                    text-decoration: underline;

                                }
                            }
                        }
                    }

                    .set {
                        line-height: 0.32rem;
                        color: #fff;

                        .icon-shezhi {
                            font-size: 0.4rem;
                        }
                    }
                }

                .merchantwork-wrap-top-b {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 0.25rem;
                    margin-top: 0.69rem;

                    li {
                        width: 33%;
                        text-align: center;
                        border-right: 1px solid #ff7e5f;
                        font-size: 0.28rem;
                        color: #ffffff;

                        p:nth-child(1) {
                            margin-bottom: 0.2rem;
                            font-size: 0.26rem;
                            color: #ededed;
                        }
                    }

                    li:last-child {
                        border: none;
                    }
                }
            }

            .merchantwork-wrap-center {
                width: calc(100% - 0.48rem);
                margin: 0 auto;
                height: 1.88rem;
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
                display: flex;

                div:nth-child(1) {
                    width: 3.26rem;
                    height: 1.88rem;
                    background: #4b93ff;
                    border-radius: 0.07rem;
                    margin-right: 0.4rem;
                    text-align: center;

                    img {
                        width: 0.6rem;
                        height: 0.6rem;
                    }

                    p {
                        font-size: 0.3rem;
                        text-align: center;
                        color: #fff;
                    }
                }

                div:nth-child(2) {
                    width: 3.26rem;
                    height: 1.88rem;
                    background: #ffaa23;
                    border-radius: 0.07rem;
                    text-align: center;

                    img {
                        width: 0.6rem;
                        height: 0.6rem;
                    }

                    p {
                        font-size: 0.3rem;
                        color: #fff;
                    }
                }
            }

            .merchantwork-wrap-bottom {
                margin-bottom: 0.48rem;

                .tool {
                    font-size: 0.29rem;
                    margin-left: 0.29rem;
                    margin-bottom: 0.22rem;
                }

                .merchantwork-wrap-bottom-list {
                    width: 100%;

                    .merchantwork-wrap-bottom-list-1 {
                        width: calc(100% - 0.48rem);
                        margin: 0 auto;
                        background: #fff;
                        display: flex;
                        flex-wrap: wrap;
                        padding-bottom: 0.5rem;
                        box-sizing: border-box;

                        li {
                            width: 20%;
                            text-align: center;

                            img {
                                width: 0.45rem;
                                height: 0.5rem;
                                object-fit: contain;
                            }

                            p {
                                text-align: center;
                                font-size: 0.25rem;
                            }
                        }
                    }
                }
            }

            .merchantwork-wrap-footer {
                p {
                    width: calc(100% - 0.48rem);
                    margin: 0 auto;
                    height: 0.81rem;
                    background: #e14e2a;
                    color: #fff;
                    text-align: center;
                    line-height: 0.81rem;
                    border-radius: 0.08rem;
                    font-size: 0.29rem;
                }
            }
			.footer1 {
			    width: 100%;
			    height: 1.73rem;
			    background: #f6f6f6;
			    font-size: 0.23rem;
			    text-align: center;
			    color: #d1d1d1;
			    padding-top: 0.4rem;
			    box-sizing: border-box;
			
			    p:nth-child(2) {
			        margin-top: 0.16rem;
			    }
			}
        }
    }
</style>
